import { template as template_761475aa67ee4a70b3476c3ad0b38643 } from "@ember/template-compiler";
const WelcomeHeader = template_761475aa67ee4a70b3476c3ad0b38643(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
