import { template as template_7a33997020fc4a43a7bbd609813b047a } from "@ember/template-compiler";
const FKControlMenuContainer = template_7a33997020fc4a43a7bbd609813b047a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
