import { template as template_731365bfabe6413ba023c2b75a12ad12 } from "@ember/template-compiler";
const SidebarSectionMessage = template_731365bfabe6413ba023c2b75a12ad12(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
